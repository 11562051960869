import axios from 'axios'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useAbility } from '@casl/react';

import CreateMerchantModal from '../components/merchant/CreateMerchantModal';
import CreateStatementOptionsModal from '../components/merchant/statementOption/CreateStatementOptionModal';
import MerchantRecord from '../components/merchant/MerchantRecord';
import Paginator from '../components/pagination/Paginator';
import PerPage from '../components/pagination/PerPage';
import useLogout from '../hooks/useLogout';
import useRedirectToHome from '../hooks/useRedirectToHome';
import usePagination from '../hooks/usePagination';
import CreateUPIModal from '../components/merchant/upi/CreateUpiModal';
import CreateNEFTModal from '../components/merchant/neft/CreateNeftModal';
import UpdateUPIModal from '../components/merchant/upi/UpdateUpiModal';
import UpdateNEFTModal from '../components/merchant/neft/UpdateNeftModal';
import UpdateMerchantModal from '../components/merchant/UpdateMerchantModal';
import UpdateStatementOptionsModal from '../components/merchant/statementOption/UpdateStatementOptionModal';
import { AbilityContext, Can } from '../helpers/Can';

const Merchants = () => {
  const logoutUser = useLogout();
  const redirectToHome = useRedirectToHome();
  const ability = useAbility(AbilityContext);

  useEffect(() => {
    // console.log(ability, ability.cannot('read', 'Merchant'))
    if (ability && ability.cannot('read', 'Merchant')) {
      redirectToHome()
    }
    return () => { }
  }, [ability])


  const { limit, setLimit, perPageArr, page, setPage, totalPage, setTotalPage, totalRecords, setTotalRecords } = usePagination({ limit: 10 })

  const [merchants, setMerchants] = useState(null)

  const [showCreateMerchant, setShowCreateMerchant] = useState(false)
  const [showUpdateMerchant, setShowUpdateMerchant] = useState(false)
  const [updateMerchantData, setUpdateMerchantData] = useState({ merchant: null, name: null, min: null, max: null, callBackUrl: null, default: null, owner: null })

  const [showCreateUPI, setShowCreateUPI] = useState(false)
  const [createUpiData, setCreateUpiData] = useState({ merchant: null })
  const [showUpdateUPI, setShowUpdateUPI] = useState(false)
  const [updateUpiData, setUpdateUpiData] = useState({ upi: null, merchant: null, name: null, vpa: null, enabled: null })

  const [showCreateNEFT, setShowCreateNEFT] = useState(false)
  const [createNeftData, setCreateNeftData] = useState({ merchant: null })
  const [showUpdateNEFT, setShowUpdateNEFT] = useState(false)
  const [updateNeftData, setUpdateNeftData] = useState(
    { neft: null, merchant: null, acNumber: null, acHolderName: null, ifscCode: null, bankName: null, enabled: null }
  )

  const [showCreateStatementOption, setShowCreateStatementOption] = useState(false)
  const [createStatementOptionData, setCreateStatementOptionData] = useState({ merchant: null })
  const [showUpdateStatementOption, setShowUpdateStatementOption] = useState(false)
  const [updateStatementOptionData, setUpdateStatementOptionData] = useState({
    merchant: null,
    id: null,
    name: null,
    prefix: null,
    stFileType: null,
    stAllKeys: null,
    stAmountKey: null,
    stRefNumberKey: null,
    stRefNumberSeparator: null,
    stRefNumberIndex: 0,
    default: null,
    stCrKey: null,
    stCrValue: null,
    stTransDateKey: null,
  })

  const getMerchants = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/merchant/all?page=${page}&limit=${limit}`)
      .then((response) => {
        if (response.status == 200) {
          const { data } = response;
          setMerchants(data.merchants)
          setTotalRecords(data.pagination.totalRecords)
          setTotalPage(data.pagination.totalPage)
          setPage(data.pagination.currentPage)
        } else {
          setMerchants(null)
          setTotalRecords(0)
          setTotalPage(0)
          setPage(1)
        }
      })
      .catch((error) => {
        const { response } = error
        if (response) {
          if (response?.status == 401) {
            toast.error("Please Login!");
            logoutUser();
          }
        }
        setMerchants(null)
        setTotalRecords(0)
        setTotalPage(0)
        setPage(1)
      })
  }

  useEffect(() => {
    getMerchants()

    return () => { }
  }, [page, limit])

  // Create Merchant Handler Start
  const handleCreateMerchantShow = () => {
    setShowCreateMerchant(true)
  }
  const handleCreateMerchantClose = () => {
    setShowCreateMerchant(false)
  }
  const createMerchantModalClose = (type) => {
    handleCreateMerchantClose()
    if (type == "success") {
      getMerchants()
    }
  }
  // Create Merchant Handler End

  // Update Merchant Handler Start
  const handleUpdateMerchantShow = (id, name, min, max, callBackUrl, def, owner) => {
    setUpdateMerchantData({ merchant: id, name: name, min: min, max: max, callBackUrl: callBackUrl, default: def, owner: owner })
    setShowUpdateMerchant(true)
  }
  const handleUpdateMerchantClose = () => {
    setUpdateMerchantData({ merchant: null, name: null, min: null, max: null, callBackUrl: null, default: null, owner: null })
    setShowUpdateMerchant(false)
  }
  const updateMerchantModalClose = (type) => {
    handleUpdateMerchantClose()
    if (type == "success") {
      getMerchants()
    }
  }
  // Update Merchant Handler End

  // Create New UPI Handler Start
  const handleCreateUPIShow = (merchant) => {
    setCreateUpiData({ merchant })
    setShowCreateUPI(true)
  }
  const handleCreateUPIClose = () => {
    setCreateUpiData({ merchant: null })
    setShowCreateUPI(false)
  }
  const createUPIModalClose = (type) => {
    handleCreateUPIClose()
    if (type == "success") {
      getMerchants()
    }
  }
  // Create New UPI Handler End

  // Update UPI Handler Start
  const handleUpdateUPIShow = (merchant, upi) => {
    if (ability && ability.can('update', 'Upi')) {
      setUpdateUpiData({ upi: upi._id, merchant: merchant, name: upi.name, vpa: upi.vpa, enabled: upi.enabled })
      setShowUpdateUPI(true)
    } else {
      toast.error("Can't update Upi!");
    }
  }
  const handleUpdateUPIClose = () => {
    setUpdateUpiData({ upi: null, merchant: null, name: null, vpa: null, enabled: null })
    setShowUpdateUPI(false)
  }
  const updateUPIModalClose = (type) => {
    handleUpdateUPIClose()
    if (type == "success") {
      getMerchants()
    }
  }
  // Update UPI Handler End

  // Create New NEFT Handler Start
  const handleCreateNEFTShow = (merchant) => {
    setCreateNeftData({ merchant })
    setShowCreateNEFT(true)
  }
  const handleCreateNEFTClose = () => {
    setCreateNeftData({ merchant: null })
    setShowCreateNEFT(false)
  }
  const createNEFTModalClose = (type) => {
    handleCreateNEFTClose()
    if (type == "success") {
      getMerchants()
    }
  }
  // Create New NEFT Handler End

  // Update NEFT Handler Start
  const handleUpdateNEFTShow = (merchant, neft) => {
    if (ability && ability.can('update', 'Neft')) {
      setUpdateNeftData({
        neft: neft._id, merchant: merchant, acNumber: neft.acNumber, acHolderName: neft.acHolderName, ifscCode: neft.ifscCode, bankName: neft.bankName, enabled: neft.enabled
      })
      setShowUpdateNEFT(true)
    } else {
      toast.error("Can't update Neft!");
    }
  }
  const handleUpdateNEFTClose = () => {
    setUpdateNeftData({ neft: null, merchant: null, acNumber: null, acHolderName: null, ifscCode: null, bankName: null, enabled: null })
    setShowUpdateNEFT(false)
  }
  const updateNEFTModalClose = (type) => {
    handleUpdateNEFTClose()
    if (type == "success") {
      getMerchants()
    }
  }
  // Update UPI Handler End

  // Create Statement Option Handler Start
  const handleCreateStatementOptionShow = (merchant) => {
    setCreateStatementOptionData({ merchant: merchant })
    setShowCreateStatementOption(true)
  }
  const handleCreateStatementOptionClose = () => {
    setCreateStatementOptionData({ merchant: null })
    setShowCreateStatementOption(false)
  }
  const createStatementOptionModalClose = (type) => {
    handleCreateStatementOptionClose()
    if (type == "success") {
      getMerchants()
    }
  }
  // Create Statement Option Handler End

  // Update Statement Option Handler Start
  const handleUpdateStatementOptionShow = (merchant, type) => {
    if (ability && ability.can('update', 'StatementType')) {
      // console.log(merchant, type)
      setUpdateStatementOptionData({
        merchant: merchant,
        id: type._id,
        name: type.name,
        prefix: type.prefix,
        stFileType: type.stFileType,
        stAllKeys: type.stAllKeys,
        stAmountKey: type.stAmountKey,
        stRefNumberKey: type.stRefNumberKey,
        stRefNumberSeparator: type.stRefNumberSeparator,
        stRefNumberIndex: type.stRefNumberIndex || 0,
        default: type.default,
        stCrKey: type.stCrKey,
        stCrValue: type.stCrValue,
        stTransDateKey: type.stTransDateKey,
      })
      setShowUpdateStatementOption(true)
    } else {
      toast.error("Can't update Statement Option!");
    }
  }
  const handleUpdateStatementOptionClose = () => {
    setUpdateStatementOptionData({
      merchant: null,
      id: null,
      name: null,
      prefix: null,
      stFileType: null,
      stAllKeys: null,
      stAmountKey: null,
      stRefNumberKey: null,
      stRefNumberSeparator: null,
      stRefNumberIndex: 0,
      default: null,
      stCrKey: null,
      stCrValue: null,
      stTransDateKey: null,
    })
    setShowUpdateStatementOption(false)
  }
  const updateStatementOptionModalClose = (type) => {
    handleUpdateStatementOptionClose()
    if (type == "success") {
      getMerchants()
    }
  }
  // Update Statement Option Handler End


  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-4">Merchants
          <span className='ms-2 pointer' onClick={getMerchants}>
            <i className="ti ti-reload"></i>
          </span>
        </h5>

        <div>
          <Can I="create" a="Merchant">
            <>
              <button className="btn btn-primary rounded-3 fw-semibold" onClick={handleCreateMerchantShow}>Create New</button>
              <CreateMerchantModal show={showCreateMerchant} handleClose={createMerchantModalClose} />
            </>
          </Can>

          <UpdateMerchantModal show={showUpdateMerchant} updateData={updateMerchantData} handleClose={updateMerchantModalClose} />

          <CreateUPIModal show={showCreateUPI} createData={createUpiData} handleClose={createUPIModalClose} />
          <UpdateUPIModal show={showUpdateUPI} updateData={updateUpiData} handleClose={updateUPIModalClose} />

          <CreateNEFTModal show={showCreateNEFT} createData={createNeftData} handleClose={createNEFTModalClose} />
          <UpdateNEFTModal show={showUpdateNEFT} updateData={updateNeftData} handleClose={updateNEFTModalClose} />

          <CreateStatementOptionsModal show={showCreateStatementOption} handleClose={createStatementOptionModalClose} createData={createStatementOptionData} />
          <UpdateStatementOptionsModal show={showUpdateStatementOption} handleClose={updateStatementOptionModalClose} updateData={updateStatementOptionData} />

          <div className="row mt-1">
            <div className="col">
              <PerPage limit={limit} setLimit={setLimit} perPageArr={perPageArr} totalRecords={totalRecords} />
            </div>
          </div>
          {merchants && !!merchants.length && (
            <>
              <div className="table-responsive w-100">
                <table className="table text-nowrap mb-0 align-middle">
                  <thead className="text-dark fs-4">
                    <tr>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">Name</h6>
                      </th>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">Owner</h6>
                      </th>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">ID</h6>
                      </th>
                      <Can I="read" a="Upi">
                        <th className="border-bottom-0">
                          <h6 className="fw-semibold mb-0">UPI</h6>
                        </th>
                      </Can>
                      <Can I="read" a="Neft">
                        <th className="border-bottom-0">
                          <h6 className="fw-semibold mb-0">NEFT</h6>
                        </th>
                      </Can>
                      <Can I="read" a="StatementType">
                        <th className="border-bottom-0">
                          <h6 className="fw-semibold mb-0">Statement Options</h6>
                        </th>
                      </Can>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">Options</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {merchants.map((merchant, key) => (
                      <MerchantRecord key={key} merchant={merchant}
                        showUpdateMerchant={handleUpdateMerchantShow}
                        showCreateUPI={handleCreateUPIShow}
                        showUpdateUPI={handleUpdateUPIShow}
                        showCreateNEFT={handleCreateNEFTShow}
                        showUpdateNEFT={handleUpdateNEFTShow}
                        showCreateStatementOption={handleCreateStatementOptionShow}
                        showUpdateStatementOption={handleUpdateStatementOptionShow} />
                    ))}
                  </tbody>
                </table>
              </div>

              <Paginator page={page} setPage={setPage} totalPage={totalPage} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Merchants