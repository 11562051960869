import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast';

function UserPermissions({ perms, user, getUsers }) {
    const [isLoading, setIsLoading] = useState(false)
    const [permission, setPermission] = useState(perms)

    useEffect(() => {

        setPermission(perms)

        return () => { }
    }, [perms])


    const isPermissionChanged = useMemo(() => {
        const isEqual = _.isEqual(perms, permission)
        return !isEqual
    }, [perms, permission])

    const toggleValue = (e) => {
        try {
            const subject = e.target.name.split('_')[0]
            const action = e.target.name.split('_')[1]
            const value = !permission[subject][action]
            // console.log(subject, action, value)
            // console.log({ ...permission, [subject]: { ...permission[subject], [action]: value } })
            setPermission((prev) => { return { ...prev, [subject]: { ...prev[subject], [action]: value } } })
        } catch (error) {

        }
    }

    const updatePermission = async () => {
        const { _id, dashboard, merchant, upi, neft, statementOption, order, statement } = permission
        const data = { user, _id, dashboard, merchant, upi, neft, statementOption, order, statement }
        setIsLoading(true)
        await axios
            .put(`${process.env.REACT_APP_API_URL}/user/update_permission`, data)
            .then((response) => {
                const { data } = response;
                if (response.status == 200) {
                    toast.success(data.msg);
                    getUsers()
                } else {
                    toast.error(data.msg);
                }
            })
            .catch((error) => {
                const { response } = error
                if (response) {
                    const { data } = response
                    if (response?.status == 401) {
                        toast.error("Please Login!");
                        logoutUser();
                    } else if (response?.status == 400) {
                        toast.error(data.msg);
                    } else {
                        toast.error(data.msg || "Request failed!");
                    }
                }
            })
            .finally((e) => {
                setIsLoading(false)
            });
    }

    const resetPermission = async () => {
        setPermission(perms)
    }
    return (
        <div className='d-flex flex-column gap-2'>
            {permission && (
                <>
                    <div className="d-flex gap-3">
                        <div className="d-flex flex-column gap-2">
                            <small className='fw-semibold '>Dashboard</small>
                            <div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id={`dashboard_read_${permission._id}`} name="dashboard_read"
                                        checked={permission?.dashboard?.read} onChange={toggleValue} />
                                    <label className="form-check-label " htmlFor={`dashboard_read_${permission._id}`}>Read</label>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <small className='fw-semibold '>Merchant</small>
                            <div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id={`merchant_read_${permission._id}`} name="merchant_read"
                                        checked={permission?.merchant?.read} onChange={toggleValue} />
                                    <label className="form-check-label " htmlFor={`merchant_read_${permission._id}`}>Read</label>
                                </div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id={`merchant_create_${permission._id}`} name="merchant_create"
                                        checked={permission?.merchant?.create} onChange={toggleValue} />
                                    <label className="form-check-label " htmlFor={`merchant_create_${permission._id}`}>Create</label>
                                </div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id={`merchant_update_${permission._id}`} name="merchant_update"
                                        checked={permission?.merchant?.update} onChange={toggleValue} />
                                    <label className="form-check-label " htmlFor={`merchant_update_${permission._id}`}>Update</label>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-column gap-2">
                            <small className='fw-semibold '>UPI</small>
                            <div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id={`upi_read_${permission._id}`} name="upi_read"
                                        checked={permission?.upi?.read} onChange={toggleValue} />
                                    <label className="form-check-label " htmlFor={`upi_read_${permission._id}`}>Read</label>
                                </div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id={`upi_create_${permission._id}`} name="upi_create"
                                        checked={permission?.upi?.create} onChange={toggleValue} />
                                    <label className="form-check-label " htmlFor={`upi_create_${permission._id}`}>Create</label>
                                </div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id={`upi_update_${permission._id}`} name="upi_update"
                                        checked={permission?.upi?.update} onChange={toggleValue} />
                                    <label className="form-check-label " htmlFor={`upi_update_${permission._id}`}>Update</label>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-column gap-2">
                            <small className='fw-semibold '>NEFT</small>
                            <div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id={`neft_read_${permission._id}`} name="neft_read"
                                        checked={permission?.neft?.read} onChange={toggleValue} />
                                    <label className="form-check-label " htmlFor={`neft_read_${permission._id}`}>Read</label>
                                </div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id={`neft_create_${permission._id}`} name="neft_create"
                                        checked={permission?.neft?.create} onChange={toggleValue} />
                                    <label className="form-check-label " htmlFor={`neft_create_${permission._id}`}>Create</label>
                                </div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id={`neft_update_${permission._id}`} name="neft_update"
                                        checked={permission?.neft?.update} onChange={toggleValue} />
                                    <label className="form-check-label " htmlFor={`neft_update_${permission._id}`}>Update</label>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-column gap-2">
                            <small className='fw-semibold '>Statement Option</small>
                            <div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id={`statementOption_read_${permission._id}`} name="statementOption_read"
                                        checked={permission?.statementOption?.read} onChange={toggleValue} />
                                    <label className="form-check-label " htmlFor={`statementOption_read_${permission._id}`}>Read</label>
                                </div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id={`statementOption_create_${permission._id}`} name="statementOption_create"
                                        checked={permission?.statementOption?.create} onChange={toggleValue} />
                                    <label className="form-check-label " htmlFor={`statementOption_create_${permission._id}`}>Create</label>
                                </div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id={`statementOption_update_${permission._id}`} name="statementOption_update"
                                        checked={permission?.statementOption?.update} onChange={toggleValue} />
                                    <label className="form-check-label " htmlFor={`statementOption_update_${permission._id}`}>Update</label>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-column gap-2">
                            <small className='fw-semibold '>Order</small>
                            <div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id={`order_read_${permission._id}`} name="order_read"
                                        checked={permission?.order?.read} onChange={toggleValue} />
                                    <label className="form-check-label " htmlFor={`order_read_${permission._id}`}>Read</label>
                                </div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id={`order_approve_${permission._id}`} name="order_approve"
                                        checked={permission?.order?.approve} onChange={toggleValue} />
                                    <label className="form-check-label " htmlFor={`order_approve_${permission._id}`}>Approve</label>
                                </div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id={`order_cancel_${permission._id}`} name="order_cancel"
                                        checked={permission?.order?.cancel} onChange={toggleValue} />
                                    <label className="form-check-label " htmlFor={`order_cancel_${permission._id}`}>Cancel</label>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-column gap-2">
                            <small className='fw-semibold '>Statement</small>
                            <div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id={`statement_read_${permission._id}`} name="statement_read"
                                        checked={permission?.statement?.read} onChange={toggleValue} />
                                    <label className="form-check-label " htmlFor={`statement_read_${permission._id}`}>Read</label>
                                </div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id={`statement_upload_${permission._id}`} name="statement_upload"
                                        checked={permission?.statement?.upload} onChange={toggleValue} />
                                    <label className="form-check-label " htmlFor={`statement_upload_${permission._id}`}>Upload</label>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="d-flex">
                        <button type="button" className="btn btn-info btn-sm" disabled={isLoading || !isPermissionChanged}
                            onClick={updatePermission}>Update</button>
                        <button type="button" className="btn btn-warning btn-sm ms-2" disabled={isLoading || !isPermissionChanged}
                            onClick={resetPermission}>Reset</button>
                    </div>

                </>
            )}
        </div>
    )
}

export default UserPermissions
