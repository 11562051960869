import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import * as Yup from "yup";
import useCustomValidation from '../../../hooks/useCustomValidation';
import useLogout from '../../../hooks/useLogout';
import ValidationError from '../../ValidationError';

function UpdateStatementOptionsModal({ show, handleClose, updateData }) {
    const logoutUser = useLogout();

    const [isLoading, setIsLoading] = useState(false);
    const [stAllKeys, setStAllKeys] = useState([])
    const [editstAllKeys, setEditStAllKeys] = useState(false)
    const [defaultSt, setDefaultSt] = useState(null);

    const schema = {
        name: Yup.string().required("Name is required").trim(),
        prefix: Yup.string().required("Prefix is required").trim(),
        stFileType: Yup.string().required("File Type is required"),
        stAllKeys: Yup.array().required("All Keys are required"),
        stAmountKey: Yup.string().required("Amount Key is required"),
        stRefNumberKey: Yup.string().required("UTR Number Key is required"),
        stRefNumberSeparator: Yup.string().required("UTR Number Separator is required"),
        stRefNumberIndex: Yup.number().required("UTR Number Index is required"),
        default: Yup.bool().required(),
        stCrKey: Yup.string().nullable(),
        stCrValue: Yup.string().nullable(),
        stTransDateKey: Yup.string().nullable(),

    };
    const initial = {
        name: null,
        prefix: null,
        stFileType: null,
        stAllKeys: null,
        stAmountKey: null,
        stRefNumberKey: null,
        stRefNumberSeparator: null,
        stRefNumberIndex: 0,
        default: null,
        stCrKey: null,
        stCrValue: null,
        stTransDateKey: null,
    };
    const { register, errors, handleSubmit, reset, setValue, getValues } =
        useCustomValidation({ schema, initial });

    useEffect(() => {
        setValue("name", updateData.name)
        setValue("prefix", updateData.prefix)
        setValue("stFileType", updateData.stFileType)
        setValue("stAllKeys", updateData.stAllKeys)
        setValue("stAmountKey", updateData.stAmountKey)
        setValue("stRefNumberKey", updateData.stRefNumberKey)
        setValue("stRefNumberSeparator", updateData.stRefNumberSeparator)
        setValue("stRefNumberIndex", updateData.stRefNumberIndex)
        setValue("default", updateData.default)
        setValue("stCrKey", updateData.stCrKey)
        setValue("stCrValue", updateData.stCrValue)
        setValue("stTransDateKey", updateData.stTransDateKey)

        setStAllKeys(updateData.stAllKeys)
        setDefaultSt(updateData.default)

        return () => { }
    }, [updateData])

    const toggleActive = () => {
        const status = getValues("default")
        setValue("default", !status)
        setDefaultSt(!status)
    }

    const updateStatementOptionSubmit = async (data) => {
        // console.log(data)
        setIsLoading(true)
        await axios
            .put(`${process.env.REACT_APP_API_URL}/merchant/statement_type/update`,
                { ...data, id: updateData.id, merchant: updateData.merchant })
            .then((response) => {
                const { data } = response;
                if (response.status == 200) {
                    toast.success(data.msg);
                    reset()
                    setStAllKeys([])
                    handleClose("success")
                } else {
                    toast.error(data.msg);
                }
            })
            .catch((error) => {
                const { response } = error
                if (response) {
                    const { data } = response
                    if (response?.status == 401) {
                        toast.error("Please Login!");
                        logoutUser();
                    } else if (response?.status == 400) {
                        toast.error(data.msg);
                    } else {
                        toast.error(data.msg || "Request failed!");
                    }
                }
            })
            .finally((e) => {
                setIsLoading(false)
            });

    }

    const saveStAllKeys = (e) => {
        try {
            e.preventDefault()
            const target = document.getElementById("u-stopt-allKeys")
            const targetVal = target.value

            const allKeyArray = targetVal.split(",") || []
            setValue("stAllKeys", allKeyArray)
            setStAllKeys(allKeyArray)
            setEditStAllKeys(false)
            return
        } catch (error) {
            toast.error("Something went wrong!")
        }
    }

    const editStAll = () => {
        setEditStAllKeys(true)
    }

    const clearStAll = () => {
        setValue("stAllKeys", [])
        setStAllKeys([])
    }

    const handleOnHide = () => {
        reset()
        setStAllKeys([])
        handleClose("manual")
        setIsLoading(false)
    }

    return (
        defaultSt != null && (
            <Modal show={show} onHide={handleOnHide} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Update Statement Option</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(updateStatementOptionSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='u-stopt-name' className="form-label">Name</label>
                                    <input type="text" {...register("name")} className="form-control"
                                        id="u-stopt-name" />
                                    <ValidationError name="name" errors={errors} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='u-stopt-file-type' className="form-label">File Type</label>
                                    <select id="u-stopt-file-type" className="form-select" {...register("stFileType")}>
                                        <option value="" disabled>
                                            Select File Type
                                        </option>
                                        <option value="csv">CSV</option>
                                        <option value="excel">Excel</option>
                                    </select>
                                    <ValidationError name="stFileType" errors={errors} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor='u-stopt-allKeys' className="form-label">All Statement Keys <small>(enter comma(,) seperated in sequence)</small></label>

                                    <div className="d-flex flex-wrap align-items-center gap-2 ">
                                        {editstAllKeys && (
                                            <div className="input-group mb-3">
                                                <input type="text" {...register("stAllKeys")} className='form-control'
                                                    id="u-stopt-allKeys" />
                                                <button onClick={saveStAllKeys} className="btn btn-success" type="button">
                                                    <i className="ti ti-device-floppy me-1 fs-6"></i>
                                                </button>
                                                <button onClick={clearStAll} className="btn btn-danger" type="button">
                                                    <i className="ti ti-eraser me-1 fs-6"></i>
                                                </button>
                                            </div>

                                        ) || (
                                                stAllKeys && !!stAllKeys.length && (
                                                    <>
                                                        {stAllKeys.map((head, key) => (
                                                            <button type='button' key={key} className="btn statement-type-btn btn-sm btn-outline-secondary rounded-3 fw-semibold position-relative px-3 mx-1 mt-1">
                                                                {head}
                                                            </button>
                                                        ))}
                                                        <button type='button' onClick={editStAll}
                                                            className="d-flex align-items-center btn btn-sm btn-outline-info rounded-3 fw-semibold position-relative px-3 mx-1 mt-1">
                                                            <i className="ti ti-edit me-1 fs-5"></i>
                                                            <span>Edit</span>
                                                        </button>
                                                    </>
                                                )
                                            )}
                                    </div>
                                    <ValidationError name="stAllKeys" errors={errors} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='u-stopt-prefix' className="form-label">Prefix</label>
                                    <input type="text" {...register("prefix")} className="form-control"
                                        id="u-stopt-prefix" />
                                    <ValidationError name="prefix" errors={errors} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='u-stopt-amtKey' className="form-label">Amount Key</label>
                                    <select id="u-stopt-amtKey" className="form-select" {...register("stAmountKey")}>
                                        <option value="" disabled>
                                            Select Amount Key
                                        </option>
                                        {stAllKeys && !!stAllKeys.length && (
                                            stAllKeys.map((val, key) => (
                                                <option key={key} value={val}>{val}</option>
                                            ))
                                        )}
                                    </select>
                                    <ValidationError name="stAmountKey" errors={errors} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='u-stopt-refNumberKey' className="form-label">UTR Number Key</label>
                                    <select id="u-stopt-refNumberKey" className="form-select" {...register("stRefNumberKey")}>
                                        <option value="" disabled>
                                            Select UTR Number Key
                                        </option>
                                        {stAllKeys && !!stAllKeys.length && (
                                            stAllKeys.map((val, key) => (
                                                <option key={key} value={val}>{val}</option>
                                            ))
                                        )}
                                    </select>
                                    <ValidationError name="stRefNumberKey" errors={errors} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="mb-3">
                                    <label htmlFor='u-stopt-utr-separator' className="form-label">UTR Number Separator</label>
                                    <input type="text" {...register("stRefNumberSeparator")} className="form-control"
                                        id="u-stopt-utr-separator" />
                                    <ValidationError name="stRefNumberSeparator" errors={errors} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="mb-3">
                                    <label htmlFor='u-stopt-refNumberIndex' className="form-label">UTR Number Index</label>
                                    <select id="u-stopt-refNumberIndex" className="form-select" {...register("stRefNumberIndex")}>
                                        <option value="" disabled>
                                            Select UTR Number Index
                                        </option>
                                        <option value={0}>0</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                    </select>
                                    <ValidationError name="stRefNumberIndex" errors={errors} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='u-stopt-default' className="form-label">Default</label>
                                    <div className="form-check form-switch">
                                        <input {...register("default")} value={defaultSt} onChange={toggleActive} className="form-check-input" type="checkbox"
                                            id="u-stopt-default" checked={defaultSt} />
                                        <label className="form-check-label" htmlFor="u-stopt-default">Statement Option is {defaultSt ? "" : "not"} set as default</label>
                                    </div>
                                    <ValidationError name="default" errors={errors} />
                                </div>
                            </div>
                        </div>

                        {/* <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='u-stopt-crValue' className="form-label">Credit Compare Value</label>
                                    <input {...register("stCrValue")} type="text" className="form-control"
                                        id="u-stopt-crValue" />
                                    <ValidationError name="stCrValue" errors={errors} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='u-stopt-tranDateKey' className="form-label">Trans. Date Key</label>
                                    <select id="u-stopt-tranDateKey" className="form-select" {...register("stTransDateKey")}>
                                        <option value="" disabled>
                                            Select Trans. Date Key
                                        </option>
                                        {stAllKeys && !!stAllKeys.length && (
                                            stAllKeys.map((val, key) => (
                                                <option key={key} value={val}>{val}</option>
                                            ))
                                        )}
                                    </select>
                                    <ValidationError name="stTransDateKey" errors={errors} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='u-stopt-crKey' className="form-label">Cr Key</label>
                                    <select id="u-stopt-crKey" className="form-select" {...register("stCrKey")}>
                                        <option value="" disabled>
                                            Select Credit Key
                                        </option>
                                        {stAllKeys && !!stAllKeys.length && (
                                            stAllKeys.map((val, key) => (
                                                <option key={key} value={val}>{val}</option>
                                            ))
                                        )}
                                    </select>
                                    <ValidationError name="stCrKey" errors={errors} />
                                </div>
                            </div>
                        </div> */}

                        <button type="submit" className="btn btn-primary"
                            disabled={isLoading}>
                            {isLoading && (
                                <div className="spinner-border"></div>
                            )} Update
                        </button>
                    </form >
                </Modal.Body>
            </Modal>
        )
    )
}

export default UpdateStatementOptionsModal