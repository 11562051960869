import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useAbility } from '@casl/react';
import _ from 'lodash';
import toast from 'react-hot-toast';

import Paginator from '../components/pagination/Paginator';
import PerPage from '../components/pagination/PerPage';
import usePagination from '../hooks/usePagination';
import UserRecord from '../components/user/UserRecord';
import CreateUserModal from '../components/user/CreateUserModal';
import useRedirectToHome from '../hooks/useRedirectToHome';
import { AbilityContext } from '../helpers/Can';
import ShareMerchantModal from '../components/user/ShareMerchantModal';
import useLogout from '../hooks/useLogout';

const Users = () => {
  const logoutUser = useLogout();
  const redirectToHome = useRedirectToHome();
  const ability = useAbility(AbilityContext);

  useEffect(() => {
    // console.log(ability, ability.cannot('read', 'User'))
    if (ability && ability.cannot('read', 'User')) {
      redirectToHome()
    }
    return () => { }
  }, [ability])

  const { limit, setLimit, perPageArr, page, setPage, totalPage, setTotalPage, totalRecords, setTotalRecords } = usePagination({ limit: 10 })

  const [users, setUsers] = useState(null)
  const [merchants, setMerchants] = useState(null)

  const [showCreateUser, setShowCreateUser] = useState(false)
  const [showShareMerchant, setShowShareMerchant] = useState(false)
  const [shareMerchantData, setShareMerchantData] = useState({ _id: null, sharedMerchant: null })

  useEffect(() => {
    const getMerchants = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/order/merchants`)
        .then((response) => {
          if (response.status == 200) {
            const { data } = response;
            let modifiedMerchants = data.merchants.map((merch) => { return { value: merch._id, label: merch.name } })
            setMerchants(modifiedMerchants)
          } else {
            setMerchants(null)
          }
        })
        .catch((error) => {
          const { response } = error
          if (response) {
            if (response?.status == 401) {
              toast.error("Please Login!");
              logoutUser();
            }
          }
          setMerchants(null)
        })
    }

    if (!merchants) {
      getMerchants()
    }

    return () => { }
  }, [merchants])

  const getUsers = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/user/all?page=${page}&limit=${limit}`)
      .then((response) => {
        if (response.status == 200) {
          const { data } = response;
          setUsers(data.users)
          setTotalRecords(data.pagination.totalRecords)
          setTotalPage(data.pagination.totalPage)
          setPage(data.pagination.currentPage)
        } else {
          setUsers(null)
          setTotalRecords(0)
          setTotalPage(0)
          setPage(1)
        }
      })
      .catch((error) => {
        setUsers(null)
        setTotalRecords(0)
        setTotalPage(0)
        setPage(1)
      })
  }

  useEffect(() => {

    getUsers()

    return () => { }
  }, [page, limit])

  // Create User Handler Start
  const handleCreateUserShow = () => {
    setShowCreateUser(true)
  }
  const handleCreateUserClose = () => {
    setShowCreateUser(false)
  }
  const createUserModalClose = (type) => {
    handleCreateUserClose()
    if (type == "success") {
      getUsers()
    }
  }
  // Create User Handler End

  // Share Merchant Handler Start
  const handleShowShareMerchantShow = (userId, shared = []) => {
    let modifiedShared = shared.map((merch) => { return { value: merch._id, label: merch.name } })
    setShareMerchantData({ _id: userId, sharedMerchant: modifiedShared })
    setShowShareMerchant(true)
  }
  const handleShowShareMerchantClose = () => {
    setShareMerchantData({ _id: null, sharedMerchant: null })
    setShowShareMerchant(false)
  }
  const showShareMerchantModalClose = (type) => {
    handleShowShareMerchantClose()
    if (type == "success") {
      getUsers()
    }
  }
  // Share = Merchant Handler End

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-4">Users
          <span className='ms-2 pointer' onClick={getUsers}>
            <i className="ti ti-reload"></i>
          </span>
        </h5>


        <div>
          <button className="btn btn-primary rounded-3 fw-semibold" onClick={handleCreateUserShow}>Create New</button>
          <CreateUserModal show={showCreateUser} handleClose={createUserModalClose} />

          <ShareMerchantModal show={showShareMerchant} shareMerchantData={shareMerchantData} merchants={merchants} handleClose={showShareMerchantModalClose} />

          <div className="row mt-1">
            <div className="col">
              <PerPage limit={limit} setLimit={setLimit} perPageArr={perPageArr} totalRecords={totalRecords} />
            </div>
          </div>

          {users && !!users.length && (
            <>
              <div className="table-responsive w-100">
                <table className="table text-nowrap mb-0 align-middle">
                  <thead className="text-dark fs-4">
                    <tr>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">Details</h6>
                      </th>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">Permissions</h6>
                      </th>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">Shared Merchants</h6>
                      </th>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">Created At</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, key) => (
                      <UserRecord user={user} key={user._id} getUsers={getUsers} handleShowShareMerchant={handleShowShareMerchantShow} />
                    ))}
                  </tbody>
                </table>
              </div>
              <Paginator page={page} setPage={setPage} totalPage={totalPage} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Users