const formatINRCurrency = (amt) => {
    if (isNaN(amt)) {
        return amt
    } else {
        return amt.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'INR'
        })
    }
}

module.exports = {
    formatINRCurrency
}